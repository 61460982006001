"use client";

import { PropsWithChildren, useEffect } from 'react';
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/contexts/authContext";
import { Source_Serif_4, Inter } from "next/font/google";

const sourceSerifPro = Source_Serif_4({
  weight: ["200", "300", "400", "600", "700", "900"],
  style: ["normal"],
  subsets: ['latin'],
  variable: '--sourceSerifPro-font'
});

const inter = Inter({
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  subsets: ['latin'],
  display: 'swap',
  variable: '--inter-font'
});

export function LayoutContent({ children }: PropsWithChildren) {
  const { toast } = useToast();
  const { isAuthStateChanging } = useAuth();

  useEffect(() => {
    if (isAuthStateChanging) {
      toast({
        title: "Checking your profile...",
        description: "Please wait while we verify your account details.",
        duration: 2000
      });
    }
  }, [isAuthStateChanging, toast]);

  return (
    <main className={`${sourceSerifPro.variable} ${inter.variable} flex flex-col`}>
      {children}
    </main>
  );
}
